import React from "react";
import LayoutEn from "../layouts/en";
import LayoutJa from "../layouts/ja";
import LayoutZh from "../layouts/zh";
import SEO from "components/seo";
import PromotionDetail from "services/PromotionDetail";

const PromotionTemplate = ({ ...props }) => {
  const data = props.pageContext;

  const LayoutBaseOnLang =
    data.locale == "en"
      ? LayoutEn
      : data.locale == "ja"
      ? LayoutJa
      : data.locale == "zh"
      ? LayoutZh
      : LayoutEn;

  const promotionUrlBaseOnLang =
    data.locale == "en"
      ? "/promotion"
      : data.locale == "ja"
      ? "/ja/promotion"
      : data.locale == "zh"
      ? "/zh/promotion"
      : "/promotion";

  return (
    <LayoutBaseOnLang location={props.location}>
      <SEO title="Promotion " />

      <PromotionDetail
        langData={"langData"}
        langKey={data.locale}
        slug={data.slug}
        homeUrl={"/"}
        data={data}
        promotionUrl={promotionUrlBaseOnLang}
      />
    </LayoutBaseOnLang>
  );
};

export default PromotionTemplate;
